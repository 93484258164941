// import PropTypes from 'prop-types';
// material-ui
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { getInitialForTeam } from "helpers";
import React from "react";
// styles
import styles from "./styles";

const useStyles = makeStyles(styles);

export default function TennisMatchScore({ tennisMatch }) {
  // const [ state, setState ] = React.useState(initialValue);
  const classes = useStyles(styles);
  const { matchScoreList } = tennisMatch;

  if (!matchScoreList) return <div />;

  return (
    <div className={classes.container}>
      <div>
        <div style={{ display: "flex" }}>
          <Typography variant="body2" className={classes.futura}>
            {getInitialForTeam(tennisMatch.players, 1)}
          </Typography>
        </div>
        <div style={{ display: "flex" }}>
          <Typography variant="body2" className={classes.futura}>
            {getInitialForTeam(tennisMatch.players, 2)}
          </Typography>
        </div>
      </div>
      <div style={{ display: "flex" }}>
        {matchScoreList.map((t) => (
          <div style={{ paddingLeft: 4 }}>
            <Typography variant="body2">{t[1]}</Typography>
            <Typography variant="body2">{t[2]}</Typography>
          </div>
        ))}
      </div>
    </div>
  );
}
