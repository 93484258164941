// material-ui
import { withStyles } from "@material-ui/styles";
// components
import App from "components/App";
import { getJsonFromUrl, getLabelForTeam } from "helpers";
import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
// styles
import styles from "./styles";

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

class Root extends Component {
  static propTypes = {};

  constructor(...args) {
    super(...args);
    this.state = {
      tennisMatch: undefined,
      loading: true,
      timestamp: 0,
      error: false,
    };
    this.listenToMatch();
    window.addEventListener("resize", this.refreshInnerHeight.bind(this));
  }

  refreshInnerHeight() {
    // We execute the same script as before
    const vh = window.innerHeight * 0.01;
    this.setState({ vh });
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }

  listenToMatch() {
    console.log("Listen To Match...");

    const urlParams = getJsonFromUrl(window.location);

    const query = window.firebase.firestore().doc(`/matches/${urlParams.m}`);

    query.onSnapshot(
      (querySnapshot) => {
        const _tennisMatch = querySnapshot.data();
        this.setState({
          tennisMatch: _tennisMatch,
          timestamp: new Date().getTime(),
          loading: false,
          error: false,
        });
      },
      (err) => {
        console.log(`Encountered error: ${err}`);
        this.setState({
          error: true,
        });
      }
    );
  }

  render() {
    const { tennisMatch, loading, timestamp } = this.state;

    var title = "Loading...";

    if (tennisMatch) {
      title = `LIVE: ${getLabelForTeam(
        tennisMatch.players,
        1
      )} vs ${getLabelForTeam(tennisMatch.players, 2)}`;
    } else {
      title = "Match not found!";
    }
    return (
      <>
        <Helmet>
          <meta
            name="theme-color"
            content={tennisMatch?.rule?.color.toUpperCase() ?? "#FFFFFF"}
          />
          <title>{title}</title>
        </Helmet>
        <div
          style={{
            height: "calc(var(--vh, 1vh) * 100)",
            width: "100vw",
            overflow: "scroll",
            display: "flex",
            position: "relative",
          }}
        >
          <App
            tennisMatch={tennisMatch}
            loading={loading}
            timestamp={timestamp}
          />
        </div>
      </>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Root));
