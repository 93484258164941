// import PropTypes from 'prop-types';
// material-ui
import { fade, Grid, LinearProgress, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/styles";
import { textColorForMatchColor } from "constants/tennis";
import { getMatchStatData } from "helpers/tennis";
import React from "react";
// styles
import styles from "./styles";

const useStyles = makeStyles(styles);

export default function MatchStats({ tennisMatch }) {
  // const [ state, setState ] = React.useState(initialValue);
  const classes = useStyles(styles);

  const secondaryColor =
    textColorForMatchColor[tennisMatch?.rule?.color.toUpperCase()] ?? "#FFFFFF";

  if (!tennisMatch) return <div />;

  const statData = getMatchStatData(tennisMatch);

  const BorderLinearProgress = withStyles((theme) => ({
    root: {
      height: 10,
      borderRadius: 5,
    },
    colorPrimary: {
      border: `solid 1px ${fade(secondaryColor, 0.3)}`,
      background: fade(secondaryColor, 0.3),
    },
    bar: {
      borderRadius: 5,
      backgroundColor: "white",
    },
  }))(LinearProgress);

  function getStatItem(d) {
    return (
      <Grid container style={{ marginTop: 8 }}>
        <Grid item style={{ flex: 3, position: "relative" }}>
          <BorderLinearProgress
            value={d[1].value > 0 ? (d[1].count / d[1].value) * 100 : 0}
            variant="determinate"
            style={{ height: 20, transform: "rotate(180deg)" }}
          />
          <Typography style={{ color: secondaryColor }}>
            {d[1].value > 0
              ? `${Math.floor(d[1].count / d[1].value) * 100}%  ${
                  d[1].count
                } / ${d[1].value}`
              : "- -"}
          </Typography>
        </Grid>
        <Grid
          item
          className={classes.title}
          style={{
            flex: 1,
            minWidth: 140,
            padding: "0px 4px",
          }}
        >
          <Typography
            className={classes.futura}
            style={{ color: secondaryColor }}
          >
            {d[1].name}
          </Typography>
        </Grid>
        <Grid item style={{ flex: 3 }}>
          <BorderLinearProgress
            value={d[2].value > 0 ? (d[2].count / d[2].value) * 100 : 0}
            variant="determinate"
            style={{ height: 20 }}
          />
          <Typography style={{ color: secondaryColor }}>
            {d[2].value > 0
              ? `${Math.floor(d[2].count / d[2].value) * 100}% ${
                  d[2].count
                } / ${d[2].value}`
              : "- -"}
          </Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <div className={classes.container}>
      {statData.map((d) => getStatItem(d))}
    </div>
  );
}
