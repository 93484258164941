// import PropTypes from 'prop-types';
// material-ui
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { textColorForMatchColor } from "constants/tennis";
import { localPointOutcome } from "helpers";
import { getAllPoints, getLabelForTeam } from "helpers/tennis";
import ShotIcon from "hooks/ShotIcon";
import moment from "moment";
import React from "react";
// styles
import styles from "./styles";

const useStyles = makeStyles(styles);

export default function PointHistory({ tennisMatch }) {
  // const [ state, setState ] = React.useState(initialValue);
  const classes = useStyles(styles);
  const allPoints = getAllPoints(tennisMatch).reverse();
  allPoints.shift();
  const primaryColor = tennisMatch.rule.color.toUpperCase();
  const secondaryColor =
    textColorForMatchColor[tennisMatch.rule.color.toUpperCase()];
  const team1 = getLabelForTeam(tennisMatch.players, 1);
  const team2 = getLabelForTeam(tennisMatch.players, 2);

  return (
    <div className={classes.container}>
      <Grid container>
        {allPoints.map((p) => {
          const winner = p.winnerTeamID === 1 ? team1 : team2;
          return (
            <Grid
              item
              xs={12}
              key={p.id}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: 8,
              }}
            >
              <div style={{ padding: 4 }}>
                {p.outcome ? (
                  <ShotIcon point={p} primaryColor={primaryColor} height={30} />
                ) : (
                  <Typography
                    style={{ color: secondaryColor, paddingRight: 8 }}
                  >
                    <b>+1</b>
                  </Typography>
                )}
              </div>
              <div>
                <Typography
                  className={classes.comment}
                  style={{ color: secondaryColor }}
                  variant="caption"
                  display="block"
                >
                  {`${winner} ${localPointOutcome(p)}`}
                </Typography>
                <Typography
                  style={{ color: secondaryColor, opacity: 0.58 }}
                  variant="caption"
                >
                  {moment(p.date).fromNow()}
                </Typography>
              </div>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
}
