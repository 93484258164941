// import PropTypes from 'prop-types';
// material-ui
import { fade, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { AppLocalizations } from "constants/localization";
import { appStoreBadgeForColor, googleBadgeForColor } from "constants/tennis";
import React from "react";
// styles
import styles from "./styles";

const useStyles = makeStyles(styles);

export default function StoreBadges({ secondaryColor, primaryColor }) {
  // const [ state, setState ] = React.useState(initialValue);
  const classes = useStyles(styles);

  return (
    <div
      className={classes.root}
      style={{
        color: secondaryColor,
        borderTop: `solid 1px ${fade(secondaryColor, 0.3)}`,
      }}
    >
      <Typography variant="caption">
        {`${AppLocalizations.scoreSharedWith} `}
        <b>
          <i className="fas fa-tennis-ball"></i> Tennis Score Keepr
        </b>
      </Typography>
      <br />
      <Grid container spacing={1} justify="center">
        <Grid item>
          <img
            src={appStoreBadgeForColor[primaryColor]}
            height="35px"
            alt="AppStoreBadge"
          />
        </Grid>
        <Grid item>
          <img
            src={googleBadgeForColor[primaryColor]}
            height="35px"
            alt="GooglePlayBadge"
          />
        </Grid>
      </Grid>
    </div>
  );
}
