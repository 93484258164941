// material-ui
import {
  createMuiTheme,
  MuiThemeProvider,
  responsiveFontSizes,
} from "@material-ui/core/styles";
// custom components
import Root from "components/Root";
import "./App.css";

function App() {
  let muiTheme = createMuiTheme();

  muiTheme = responsiveFontSizes(muiTheme);

  return (
    <MuiThemeProvider theme={muiTheme}>
      <Root />
    </MuiThemeProvider>
  );
}

export default App;
