export default (theme) => ({
  title: {
    width: "20%",
    textAlign: "center",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
  },
  futura: {
    fontFamily: "Futura-Bold",
  },
  container: {
    flex: 1,
  },
});
