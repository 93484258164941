// import PropTypes from 'prop-types';
// material-ui
import { fade, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { textColorForMatchColor } from "constants/tennis";
import { getLabelForTeam, getLastPoint, localPointOutcome } from "helpers";
import ShotIcon from "hooks/ShotIcon";
import React, { useEffect } from "react";
// styles
import styles from "./styles";

const useStyles = makeStyles(styles);

export default function LastPointHeading({ tennisMatch }) {
  const [flash, setFlash] = React.useState(true);
  const classes = useStyles(styles);
  const lastPoint = getLastPoint(tennisMatch);
  const team1 = getLabelForTeam(tennisMatch.players, 1);
  const team2 = getLabelForTeam(tennisMatch.players, 2);
  const primaryColor = tennisMatch.rule.color.toUpperCase();
  const secondaryColor =
    textColorForMatchColor[tennisMatch.rule.color.toUpperCase()];

  useEffect(() => {
    setTimeout(() => setFlash(false), 10);
  }, []);

  if (!lastPoint) return <div />;

  const winner = lastPoint.winnerTeamID === 1 ? team1 : team2;

  return (
    <div
      className={classes.root}
      style={{
        backgroundColor: flash
          ? fade(secondaryColor, 0.9)
          : fade(secondaryColor, 0.3),
        border: `solid 1px ${fade(secondaryColor, 0.3)}`,
        transform: flash ? "scale(1.2)" : "scale(1)",
      }}
    >
      {lastPoint.category && (
        <ShotIcon point={lastPoint} primaryColor={primaryColor} height={80} />
      )}
      <br />
      <Typography
        variant="h4"
        className={classes.comment}
        style={{ color: secondaryColor }}
      >
        {`${winner} ${localPointOutcome(lastPoint)}`}
      </Typography>
    </div>
  );
}
