import AppStoreBadgeBlack from "assets/AppStoreBadgeBlack.svg";
import AppStoreBadgeWhite from "assets/AppStoreBadgeWhite.svg";
import GooglePlayBadgeBlack from "assets/GooglePlayBadgeBlack.svg";
import GooglePlayBadgeWhite from "assets/GooglePlayBadgeWhite.svg";

export const matchColors = [
  "#FEF100", // Ball
  "#FFFFFF", // Black
  "#03A9F4", // Hard
  "#7EC850", // Grass
  "#E3783B", // Clay
  "#576856", //AlpineGreen
  "#F46255", //Nectarine
  "#F0D56E", //LemonZest
  "#465364", //AbyssBlue
  "#EFD7D5", //ChalkPink
];

export const filterForMatchColors = {
  [matchColors[0]]: "invert(0%)",
  [matchColors[1]]: "invert(0%)",
  [matchColors[2]]: "invert(100%)",
  [matchColors[3]]: "invert(100%)",
  [matchColors[4]]: "invert(100%)",
  [matchColors[5]]: "invert(100%)",
  [matchColors[6]]: "invert(100%)",
  [matchColors[7]]: "invert(0%)",
  [matchColors[8]]: "invert(100%)",
  [matchColors[9]]: "invert(0%)",
};

export const textColorForMatchColor = {
  [matchColors[0]]: "#000000",
  [matchColors[1]]: "#000000",
  [matchColors[2]]: "#FFFFFF",
  [matchColors[3]]: "#FFFFFF",
  [matchColors[4]]: "#FFFFFF",
  [matchColors[5]]: "#FFFFFF",
  [matchColors[6]]: "#FFFFFF",
  [matchColors[7]]: "#000000",
  [matchColors[8]]: "#FFFFFF",
  [matchColors[9]]: "#000000",
};

export const appStoreBadgeForColor = {
  [matchColors[0]]: AppStoreBadgeBlack,
  [matchColors[1]]: AppStoreBadgeBlack,
  [matchColors[2]]: AppStoreBadgeWhite,
  [matchColors[3]]: AppStoreBadgeWhite,
  [matchColors[4]]: AppStoreBadgeWhite,
  [matchColors[5]]: AppStoreBadgeWhite,
  [matchColors[6]]: AppStoreBadgeWhite,
  [matchColors[7]]: AppStoreBadgeBlack,
  [matchColors[8]]: AppStoreBadgeWhite,
  [matchColors[9]]: AppStoreBadgeBlack,
};

export const googleBadgeForColor = {
  [matchColors[0]]: GooglePlayBadgeBlack,
  [matchColors[1]]: GooglePlayBadgeBlack,
  [matchColors[2]]: GooglePlayBadgeWhite,
  [matchColors[3]]: GooglePlayBadgeWhite,
  [matchColors[4]]: GooglePlayBadgeWhite,
  [matchColors[5]]: GooglePlayBadgeWhite,
  [matchColors[6]]: GooglePlayBadgeWhite,
  [matchColors[7]]: GooglePlayBadgeBlack,
  [matchColors[8]]: GooglePlayBadgeWhite,
  [matchColors[9]]: GooglePlayBadgeBlack,
};

export const scoreDictionary = {
  0: 0,
  1: 15,
  2: 30,
  3: 40,
};

export const courtAd = "ad";

export const shortPointCategory = {
  winner: "W",
  forced: "F",
  unforced: "UE",
};

export const pointCategoryWinner = "winner";
export const pointCategoryForced = "forced";
export const pointCategoryUnforced = "unforced";

export const winnerAce = "ace";
export const winnerForehand = "forehand";
export const winnerBackhand = "backhand";
export const winnerNet = "net";
export const winnerDrop = "drop";
export const winnerOverhead = "overhead";

export const unforcedDoubleFault = "doubleFault";
export const unforcedAlley = "alley";
export const unforcedOut = "out";
export const unforcedNet = "net";

export const forcedConsistency = "consistency";
export const forcedDepth = "depth";
export const forcedDirection = "direction";
export const forcedHeight = "height";
export const forcedPosition = "position";
export const forcedPower = "power";
export const forcedSpin = "spin";
export const forcedTime = "time";

export const commentForShot = {
  [`${pointCategoryWinner}_${winnerForehand}`]: "won with a winner forehand",
  [`${pointCategoryWinner}_${winnerBackhand}`]: "won with a winner backend",
  [`${pointCategoryWinner}_${winnerNet}`]: "won with a winner at the net",
  [`${pointCategoryWinner}_${winnerAce}`]: "won with an ace",
  [`${pointCategoryWinner}_${winnerOverhead}`]: "won with a winner overhead",
  [`${pointCategoryUnforced}_${unforcedDoubleFault}`]:
    "won on opponent's service fault",
  [`${pointCategoryUnforced}_${unforcedAlley}`]:
    "won on opponent's alley fault",
  [`${pointCategoryUnforced}_${unforcedOut}`]: "won on opponent's out fault",
  [`${pointCategoryUnforced}_${unforcedNet}`]: "won on opponent's net fault",
  [`${pointCategoryForced}_${forcedConsistency}`]:
    "forced opponent to fault with a concistency rally",
  [`${pointCategoryForced}_${forcedDepth}`]:
    "forced opponent to fault with depth variations",
  [`${pointCategoryForced}_${forcedDirection}`]:
    "forced opponent to fault with direction variations",
  [`${pointCategoryForced}_${forcedHeight}`]:
    "forced opponent to fault with height variations",
  [`${pointCategoryForced}_${forcedPosition}`]:
    "forced opponent to fault with position variations",
  [`${pointCategoryForced}_${forcedPower}`]:
    "forced opponent to fault with power/speed variations",
  [`${pointCategoryForced}_${forcedSpin}`]:
    "forced opponent to fault with spin variations",
  [`${pointCategoryForced}_${forcedTime}`]:
    "forced opponent to fault with shot timing variations",
};

// Switch Sides
const statusSwitchSides = "Switch Sides";

// Tie Break
const statusTieBreak = "Tie Break";

// Game Point
const statusGamePoint = "Game Point";

// Break Point
const statusBreakPoint = "Break Point";

// Set Point
const statusSetPoint = "Set Point";

// Match Point
const statusMatchPoint = "Match Point";

export function contextualStatus(status) {
  if (status === "matchPoint") {
    return statusMatchPoint;
  }
  if (status === "setPoint") {
    return statusSetPoint;
  }
  if (status === "breakPoint") {
    return statusBreakPoint;
  }
  if (status === "gamePoint") {
    return statusGamePoint;
  }

  if (status === "tieBreak") {
    return statusTieBreak;
  }

  if (status === "switchSides") {
    return statusSwitchSides;
  }

  return "";
}

export const scoreDeuce = "deuce";
export const scoreAdIn = "adIn";
export const scoreAdOut = "adOut";
export const scoreCompleted = "game";

export const ace = "aces";
export const firstServe = "firstServe";
export const doubleFault = "doubleFaults";
export const winOnFirstServe = "winOnFirstServe";
export const winOnSecondServe = "winOnSecondServe";
export const breakPointsWon = "breakPointsWon";
export const receivingPointsWon = "receivingPointsWon";
export const winners = "winners";
export const unforcedErrors = "unforcedErrors";
export const totalPointWon = "totalPointWon";

export const statisticTypeName = [
  ace,
  doubleFault,
  firstServe,
  winOnFirstServe,
  winOnSecondServe,
  breakPointsWon,
  receivingPointsWon,
  winners,
  unforcedErrors,
  totalPointWon,
];
