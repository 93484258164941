export default (theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: 600,
    margin: "auto",
    padding: 32,
    borderRadius: 8,
    transition: "all .8s ease",
    WebkitTransition: "all .8s ease",
    MozTransition: "all .8s ease",
  },
  comment: {
    fontFamily: "Futura-Bold",
    textAlign: "center",
  },
});
