export default (theme) => ({
  container: {
    background: "white",
    borderRadius: 16,
    marginTop: 22,
    boxShadow: "0px 2px 20px rgba(100,100,100,0.2)",
    height: 165,
    width: "calc(100vw - 48px)",
    overflowX: "scroll",
    overflowY: "hidden",
    display: "flex",
    WebkitScrollbar: "none",
    MsOverflowStyle: "none" /* IE and Edge */,
    scrollbarWidth: "none" /* Firefox */,
    // position: "relative",
  },
  pointBarContainer: {
    width: 25,
    height: "100%",
    position: "relative",
    margin: "0px 4px",
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
  },
  column: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  bar: {
    width: 8,
    height: "100%",
    background: "#D9D9D9",
    borderRadius: 8,
  },
  barContainer: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  gameWinner: {
    border: "solid 1px black",
    padding: 4,
    display: "flex",
    borderRadius: 4,
    flexDirection: "column",
    justifyContent: "space-between",
    height: "calc(100% - 30px)",
    width: 72,
    marginLeft: 6,
    marginRight: 6,
  },
  setWinner: {
    border: "solid 1px black",
    padding: 4,
    display: "flex",
    borderRadius: 4,
    flexDirection: "column",
    height: 118,
    width: 72,
    margin: "0px 6px",
  },
  playerWon: {
    textAlign: "center",
  },
  scoreBox: {
    width: 14,
    height: 14,
    borderRadius: 4,
    margin: 2,
    fontSize: 12,
    border: "solid 1px #D9D9D9",
  },
  scoreArea: {
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  notStarted: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    height: 132,
  },
  score: {
    position: "sticky",
    left: 0,
    background: "rgba(220,220,220,0.3)",
    borderRight: "solid 1px rgba(220,220,220,0.6)",
    backdropFilter: "blur(18px)",
    WebkitBackdropFilter: "blur(18px)",
    display: "flex",
    height: 165,
    alignItems: "center",
    padding: 4,
    zIndex: 999,
  },
});
