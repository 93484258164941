// import PropTypes from 'prop-types';
// material-ui
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {
  contextualStatus,
  filterForMatchColors,
  textColorForMatchColor,
} from "constants/tennis";
import { getLastGame, localScore } from "helpers";
import React from "react";
// styles
import styles from "./styles";

const useStyles = makeStyles(styles);

export default function ScoreBoard({ tennisMatch }) {
  // const [ state, setState ] = React.useState(initialValue);
  const classes = useStyles(styles);
  const game = getLastGame(tennisMatch);
  const splitedScore = game.score.split("-");
  const primaryColor = tennisMatch.rule.color.toUpperCase();
  const textColor =
    textColorForMatchColor[tennisMatch.rule.color.toUpperCase()];

  return (
    <div className={classes.scoreContainer}>
      <div className={classes.flex}>
        {splitedScore.length === 2 ? (
          <>
            <Typography
              style={{
                color: game.servingTeamID === 1 ? textColor : "rgba(0,0,0,0)",
                WebkitTextStroke:
                  game.servingTeamID === 1 ? undefined : `4px ${textColor}`,
              }}
              className={classes.futura}
              variant="h1"
            >
              {splitedScore[0]}
            </Typography>
            <Typography
              style={{ color: textColor, padding: "0px 4px" }}
              className={classes.futura}
              variant="h1"
            >
              -
            </Typography>
            <Typography
              className={classes.futura}
              style={{
                color: game.servingTeamID === 2 ? textColor : "rgba(0,0,0,0)",
                WebkitTextStroke:
                  game.servingTeamID === 2 ? undefined : `4px ${textColor}`,
              }}
              variant="h1"
            >
              {splitedScore[1]}
            </Typography>
          </>
        ) : (
          <Typography
            style={{ color: textColor, padding: "0px 4px" }}
            className={classes.futura}
            variant="h1"
          >
            {localScore(game.score).toUpperCase()}
          </Typography>
        )}
      </div>
      <div className={classes.flex}>
        <Typography
          style={{ color: textColor, padding: "0px 4px", height: 28 }}
          className={classes.futura}
          variant="h6"
        >
          {contextualStatus(tennisMatch.status.contextualStatus)}
        </Typography>
      </div>
      <div className={classes.flex}>
        <Typography
          className={classes.futura}
          style={{
            color: game.servingTeamID === 1 ? textColor : "rgba(0,0,0,0)",
            WebkitTextStroke:
              game.servingTeamID === 1 ? undefined : `1.5px ${textColor}`,
          }}
          variant="h4"
        >
          <img
            src="https://storage.googleapis.com/wid-images/tennis/racket.png"
            alt="racket"
            height={30}
            style={{
              filter: filterForMatchColors[primaryColor],
              paddingRight: 8,
              opacity: game.servingTeamID === 1 ? 1 : 0,
            }}
          />
          {`${tennisMatch.players[0].firstName}`.toUpperCase()}
        </Typography>
        <Typography
          style={{ color: textColor, padding: "0px 4px" }}
          className={classes.futura}
          variant="h6"
        >
          /
        </Typography>
        <Typography
          className={classes.futura}
          style={{
            color: game.servingTeamID === 2 ? textColor : "rgba(0,0,0,0)",
            WebkitTextStroke:
              game.servingTeamID === 2 ? undefined : `1.5px ${textColor}`,
          }}
          variant="h4"
        >
          {`${tennisMatch.players[1].firstName}`.toUpperCase()}
          <img
            src="https://storage.googleapis.com/wid-images/tennis/racket.png"
            alt="racket"
            height={30}
            style={{
              filter: filterForMatchColors[primaryColor],
              paddingLeft: 8,
              opacity: game.servingTeamID === 2 ? 1 : 0,
            }}
          />
        </Typography>
      </div>
    </div>
  );
}
