// import PropTypes from 'prop-types';
// material-ui
import {
  Button,
  ButtonGroup,
  CircularProgress,
  fade,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { AppLocalizations } from "constants/localization";
import { textColorForMatchColor } from "constants/tennis";
import LastPointHeading from "hooks/LastPointHeading";
import MatchStats from "hooks/MatchStats";
import PointHistory from "hooks/PointHistory";
import ScoreBoard from "hooks/ScoreBoard";
import StoreBadges from "hooks/StoreBadges";
import TennisGraph from "hooks/TennisGraph";
import moment from "moment";
import React from "react";
// styles
import styles from "./styles";

const useStyles = makeStyles(styles);
export default function App({ tennisMatch, loading, timestamp }) {
  const [index, setIndex] = React.useState(0);
  const classes = useStyles(styles);

  if (loading) {
    return (
      <div className={classes.root}>
        <div style={{ textAlign: "center" }}>
          <CircularProgress color="#ffff00" />
          <Typography style={{ fontFamily: "Futura-Bold" }} variant="h6">
            LOADING...
          </Typography>
        </div>
      </div>
    );
  }

  if (!tennisMatch || tennisMatch.uuid === undefined) {
    return (
      <div className={classes.root}>
        <div style={{ textAlign: "center" }}>
          <i class="fad fa-times" style={{ fontSize: 48 }}></i>
          <Typography style={{ fontFamily: "Futura-Bold" }} variant="h6">
            Match not found!
          </Typography>
        </div>
      </div>
    );
  }

  const rule = tennisMatch.rule;
  const primaryColor = rule.color.toUpperCase();
  const secondaryColor =
    textColorForMatchColor[tennisMatch.rule.color.toUpperCase()];

  return (
    <div className={classes.root} style={{ background: primaryColor }}>
      <div>
        <Typography
          variant="caption"
          style={{
            color: secondaryColor,
            position: "absolute",
            top: 4,
            right: 4,
          }}
        >
          {`${AppLocalizations.startedOn} `}
          {moment(tennisMatch?.date).format("lll")}
        </Typography>
      </div>
      <div className={classes.bodyContainer}>
        <ScoreBoard tennisMatch={tennisMatch} />
        <TennisGraph tennisMatch={tennisMatch} />
        <br />
        <ButtonGroup
          variant="text"
          aria-label="text primary button group"
          style={{ margin: "auto" }}
        >
          <Button
            onClick={() => setIndex(0)}
            style={{
              borderColor: secondaryColor,
              color: secondaryColor,
              background: index === 0 ? fade(secondaryColor, 0.2) : undefined,
            }}
          >
            <i class="fa fa-signal-stream" style={{ paddingRight: 4 }}></i>{" "}
            {AppLocalizations.liveFeed}
          </Button>
          <Button
            onClick={() => setIndex(1)}
            style={{
              borderColor: secondaryColor,
              color: secondaryColor,
              background: index === 1 ? fade(secondaryColor, 0.2) : undefined,
            }}
          >
            <i class="fa fa-chart-bar" style={{ paddingRight: 4 }}></i>{" "}
            {AppLocalizations.stats}
          </Button>
        </ButtonGroup>
        <div className={classes.appBarContainer}>
          {index === 0 ? (
            <div style={{ flex: 1 }}>
              <LastPointHeading tennisMatch={tennisMatch} key={timestamp} />
              <PointHistory tennisMatch={tennisMatch} />
            </div>
          ) : (
            []
          )}
          {index === 1 ? (
            <div
              style={{
                flex: 1,
                display: "flex",
              }}
            >
              <MatchStats tennisMatch={tennisMatch} />
            </div>
          ) : (
            []
          )}
        </div>
      </div>
      <StoreBadges
        secondaryColor={secondaryColor}
        primaryColor={primaryColor}
      />
    </div>
  );
}
