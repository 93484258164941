import {
  ace,
  breakPointsWon,
  courtAd,
  doubleFault,
  firstServe,
  pointCategoryUnforced,
  pointCategoryWinner,
  receivingPointsWon,
  scoreDictionary,
  statisticTypeName,
  totalPointWon,
  unforcedDoubleFault,
  unforcedErrors,
  winnerAce,
  winners,
  winOnFirstServe,
  winOnSecondServe,
} from "constants/tennis";
import moment from "moment";

export function getPlayerInitials(player) {
  return `${player.firstName[0]}${player.lastName[0] ?? ""}`.toUpperCase();
}

export function getPlayerName(player) {
  return `${player.firstName}`.toUpperCase();
}

export function getLastSet(tennisMatch) {
  if (!tennisMatch.sets) return;
  return tennisMatch.sets[tennisMatch.sets.length - 1];
}

export function getLastGame(tennisMatch) {
  const set = getLastSet(tennisMatch);
  if (!set) return;
  return set.games[set.games.length - 1];
}

export function getLastPoint(tennisMatch) {
  const game = getLastGame(tennisMatch);
  if (!game) return;
  return game.points[game.points.length - 1];
}

export function getMatchGraph(match, stopAtPoint) {
  var matchGraph = new MatchGraph();
  matchGraph.sets = [];
  matchGraph.duration = 0;
  var stop = false;
  var setCount = 0;

  var previousPoint;

  for (const setKey in match.sets) {
    if (Object.hasOwnProperty.call(match.sets, setKey)) {
      const s = match.sets[setKey];
      if (stop) break;
      setCount++;
      var _set = new SetGraph();
      _set.index = setCount;
      _set.duration = 0;
      _set.t1SetScore = 0;
      _set.t2SetScore = 0;
      _set.winnerTeamID = s.winnerTeamID;
      _set.games = [];

      for (var gameKey in s.games) {
        if (Object.hasOwnProperty.call(s.games, gameKey)) {
          const g = s.games[gameKey];
          if (stop) break;
          var _game = new GameGraph();
          _game.t1GameScore = 0;
          _game.t2GameScore = 0;
          _game.t1SetScore = _set.t1SetScore;
          _game.t2SetScore = _set.t2SetScore;
          _game.team1Serving = g.servingTeamID === 1;
          _game.duration = 0;
          _game.tieBreak = g.tieBreak;
          _game.points = [];

          for (var pointKey in g.points) {
            if (Object.hasOwnProperty.call(g.points, pointKey)) {
              const p = g.points[pointKey];
              var _point = new PointGraph();
              _point.winningPoint = false;
              _point.t1PointScore = 0;
              _point.t2PointScore = 0;
              _point.point = p;

              if (previousPoint != null) {
                _point.duration = moment
                  .duration(moment(p.date).diff(moment(previousPoint.date)))
                  .asSeconds();
              } else {
                _point.duration = moment
                  .duration(moment(p.date).diff(moment(match.date)))
                  .asSeconds();
              }
              _game.duration += _point.duration;

              if (p.winnerTeamID === 1) {
                _game.t1GameScore++;
              } else {
                _game.t2GameScore++;
              }

              _point.t1PointScore = _game.t1GameScore;
              _point.t2PointScore = _game.t2GameScore;
              _point.winnerTeamID = p.winnerTeamID;

              _point.winningPoint =
                p.id === g.points[g.points.length - 1].id && g.winnerTeamID > 0;
              _game.points.push(_point);

              if (_point.winningPoint) {
                if (g.winnerTeamID === 1) _game.t1SetScore++;
                if (g.winnerTeamID === 2) _game.t2SetScore++;
              }

              previousPoint = p;
              _set.t1SetScore = _game.t1SetScore;
              _set.t2SetScore = _game.t2SetScore;

              if (stopAtPoint != null && stopAtPoint.id === p.id) {
                stop = true;
                break;
              }
            }
          }

          _game.winnerTeamID = _game.t1GameScore > _game.t2GameScore ? 1 : 2;
          _set.duration += _game.duration;
          _set.games.push(_game);
        }
      }

      matchGraph.duration += _set.duration;
      matchGraph.sets.push(_set);
    }
  }

  return matchGraph;
}

class MatchGraph {
  constructor(sets, duration, team1Name, team2Name) {
    this.sets = sets;
    this.duration = duration;
    this.team1Name = team1Name;
    this.team2Name = team2Name;
  }
}

class SetGraph {
  constructor(
    index,
    games,
    duration,
    winnerTeamID,
    icon,
    t1SetScore,
    t2SetScore
  ) {
    this.index = index;
    this.games = games;
    this.duration = duration;
    this.winnerTeamID = winnerTeamID;
    this.icon = icon;
    this.t1SetScore = t1SetScore;
    this.t2SetScore = t2SetScore;
  }
}

class GameGraph {
  constructor(
    points,
    duration,
    winnerTeamID,
    t1GameScore,
    t2GameScore,
    t1SetScore,
    t2SetScore,
    team1Serving,
    tieBreak
  ) {
    this.points = points;
    this.duration = duration;
    this.winnerTeamID = winnerTeamID;
    this.t1GameScore = t1GameScore;
    this.t2GameScore = t2GameScore;
    this.t1SetScore = t1SetScore;
    this.t2SetScore = t2SetScore;
    this.team1Serving = team1Serving;
    this.tieBreak = tieBreak;
  }
}

class PointGraph {
  constructor(
    t1PointScore,
    t2PointScore,
    duration,
    winnerTeamID,
    winningPoint,
    icon,
    point,
    team1Serving,
    tieBreak
  ) {
    this.t1PointScore = t1PointScore;
    this.t2PointScore = t2PointScore;
    this.duration = duration;
    this.winnerTeamID = winnerTeamID;
    this.winningPoint = winningPoint;
    this.icon = icon;
    this.point = point;
  }
}

export function printDuration(seconds) {
  var date = new Date(0);
  date.setSeconds(seconds); // specify value for SECONDS here
  var timeString = date.toISOString().substr(14, 5);
  return timeString;
}

export function winningTeamID(score1, score2) {
  if (score1 <= 3 && score2 <= 3) return -1;
  if (score1 - score2 >= 2) return 1;
  if (score2 - score1 >= 2) return 2;

  return -1;
}

export function scoreLabels(score1, score2, tieBreak) {
  var scoreFourty = scoreDictionary[3].toString();
  var scoreTeam1 = score1.toString();
  var scoreTeam2 = score2.toString();

  if (tieBreak) {
    return [scoreTeam1, scoreTeam2];
  }

  if (score1 >= 3 && score2 >= 3) {
    if (score1 === score2) {
      scoreTeam1 = scoreFourty;
      scoreTeam2 = scoreFourty;
    } else if (score1 > score2) {
      scoreTeam1 = courtAd.toUpperCase();
      scoreTeam2 = scoreFourty;
    } else if (score1 < score2) {
      scoreTeam1 = scoreFourty;
      scoreTeam2 = courtAd.toUpperCase();
    }
  } else {
    scoreTeam1 = score1 > 3 ? "Ga" : scoreDictionary[score1].toString();
    scoreTeam2 = score2 > 3 ? "Ga" : scoreDictionary[score2].toString();
  }

  return [scoreTeam1, scoreTeam2];
}

export function capitalizeFirstLetter(string) {
  return (
    string.toLowerCase().charAt(0).toUpperCase() + string.toLowerCase().slice(1)
  );
}

export function getLabelForTeam(players, teamID) {
  if (players.length === 2) {
    return capitalizeFirstLetter(
      getPlayerName(teamID === 1 ? players[0] : players[1])
    );
  }

  return `${capitalizeFirstLetter(
    getPlayerName(teamID === 1 ? players[0] : players[1])
  )} & ${capitalizeFirstLetter(
    getPlayerName(teamID === 1 ? players[2] : players[3])
  )}`;
}

export function getInitialForTeam(players, teamID) {
  if (players.length === 2) {
    return getPlayerInitials(teamID === 1 ? players[0] : players[1]);
  }

  return `${getPlayerInitials(
    teamID === 1 ? players[0] : players[1]
  )} & ${getPlayerInitials(teamID === 1 ? players[2] : players[3])}`;
}

export function getAllPoints(tennisMatch) {
  const points = [];
  for (const setKey in tennisMatch.sets) {
    if (Object.hasOwnProperty.call(tennisMatch.sets, setKey)) {
      const set = tennisMatch.sets[setKey];
      for (const gameKey in set.games) {
        if (Object.hasOwnProperty.call(set.games, gameKey)) {
          const game = set.games[gameKey];
          points.push(...game.points);
        }
      }
    }
  }
  return points;
}

// STATS
const evaluationForType = {
  ace: (p, teamID) => p.outcome === winnerAce,
  doubleFault: (p, teamID) => p.outcome === unforcedDoubleFault,
  firstServe: (p, teamID) => p.serveCount === 0,
  winOnFirstServe: (p, teamID) => p.serveCount === 0,
  winOnSecondServe: (p, teamID) => p.serveCount === 1,
  receivingPointsWon: (p, teamID) => p.servingTeamID !== teamID,
  winners: (p, teamID) => p.winnerTeamID === teamID,
  unforcedErrors: (p, teamID) => p.winnerTeamID === teamID,
  totalPointWon: (p, teamID) => true,
};

const selectionForType = {
  ace: (p, teamID) => p.winnerTeamID === teamID,
  doubleFault: (p, teamID) => p.winnerTeamID !== teamID,
  firstServe: (p, teamID) => p.servingTeamID === teamID,
  winOnFirstServe: (p, teamID) => p.winnerTeamID === teamID,
  winOnSecondServe: (p, teamID) => p.winnerTeamID === teamID,
  receivingPointsWon: (p, teamID) => p.winnerTeamID === teamID,
  winners: (p, teamID) => p.category === pointCategoryWinner,
  unforcedErrors: (p, teamID) => p.category === pointCategoryUnforced,
  totalPointWon: (p, teamID) => p.winnerTeamID === teamID,
};
export function getValueCountForType(type, _points, teamIndex) {
  var res = new IntTuple();

  if (evaluationForType[type] != null && selectionForType[type] != null) {
    return getValueCountForEvaluation(
      _points,
      teamIndex,
      evaluationForType[type],
      selectionForType[type]
    );
  }

  const val = 0;
  res.count = 0;
  res.value = val;

  return res;
}

export function getTeamStatistic(forType, _points, teamIndex) {
  var teamStat = {};

  var _tuple = getValueCountForType(forType, _points, teamIndex);

  teamStat.count = _tuple.count ?? 0;
  teamStat.value = _tuple.value ?? 0;
  teamStat.teamIndex = teamIndex;
  teamStat.type = forType;
  teamStat.name = localStatisticTypeName(forType);

  return teamStat;
}

export function getMatchStatData(tennisMatch) {
  const _stats = [];
  var _points = getAllPoints(tennisMatch);

  for (const key in statisticTypeName) {
    if (Object.hasOwnProperty.call(statisticTypeName, key)) {
      const type = statisticTypeName[key];
      _stats.push({
        1: getTeamStatistic(type, _points, 1),
        2: getTeamStatistic(type, _points, 2),
      });
    }
  }

  return _stats;
}

export function getValueCountForEvaluation(
  _points,
  teamIndex,
  evaluation,
  selection
) {
  var _totalPoint = _points.filter((element) => evaluation(element, teamIndex));
  var _subSelection = _totalPoint.filter((element) =>
    selection(element, teamIndex)
  );

  return new IntTuple(_totalPoint.length, _subSelection.length);
}

class IntTuple {
  constructor(value, count) {
    this.value = value;
    this.count = count;
  }
}

function localStatisticTypeName(statisticName) {
  switch (statisticName) {
    case ace:
      return "ACES";

    case doubleFault:
      return "DOUBLE FAULTS";

    case firstServe:
      return "FIRST SERVE";

    case winOnFirstServe:
      return "WIN ON FIRST SERVE";

    case winOnSecondServe:
      return "WIN ON SECOND SERVE";

    case breakPointsWon:
      return "BREAK POINT WON";

    case receivingPointsWon:
      return "RECEIVING POINTS WON";

    case winners:
      return "WINNERS";

    case unforcedErrors:
      return "UNFORCED ERRORS";

    case totalPointWon:
      return "TOTAL POINT WON";

    default:
      return "";
  }
}
