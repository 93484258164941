export default (theme) => ({
  futura: {
    fontFamily: "Futura-Bold",
  },
  outlined: {
    WebkitTextStroke: "4px black",
    color: "rgba(0,0,0,0)",
    fontFamily: "Futura-Bold",
  },
  flex: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  scoreContainer: {},
});
