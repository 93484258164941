import { AppLocalizations } from "constants/localization";
import {
  forcedConsistency,
  forcedDepth,
  forcedDirection,
  forcedHeight,
  forcedPosition,
  forcedPower,
  forcedSpin,
  forcedTime,
  pointCategoryForced,
  pointCategoryUnforced,
  pointCategoryWinner,
  scoreAdIn,
  scoreAdOut,
  scoreCompleted,
  scoreDeuce,
  unforcedAlley,
  unforcedDoubleFault,
  unforcedNet,
  unforcedOut,
  winnerAce,
  winnerBackhand,
  winnerDrop,
  winnerForehand,
  winnerNet,
  winnerOverhead,
} from "constants/tennis";

export function localScore(score) {
  switch (score) {
    case scoreDeuce:
      return AppLocalizations.scoreDeuce;
    case scoreAdIn:
      return AppLocalizations.scoreAdIn;
    case scoreAdOut:
      return AppLocalizations.scoreAdOut;
    case scoreCompleted:
      return AppLocalizations.scoreCompleted;
    default:
      return score;
  }
}

export function localPointOutcome(point) {
  if (point.outcome === undefined || point.outcome.length === 0) {
    return AppLocalizations.wonAPoint;
  }

  if (point.category === pointCategoryWinner) {
    switch (point.outcome) {
      case winnerForehand:
        return AppLocalizations.commentForWinnerForehand;

      case winnerBackhand:
        return AppLocalizations.commentForWinnerBackhand;

      case winnerNet:
        return AppLocalizations.commentForWinnerNet;

      case winnerAce:
        return AppLocalizations.commentForWinnerAce;

      case winnerOverhead:
        return AppLocalizations.commentForWinnerOverhead;

      case winnerDrop:
        return AppLocalizations.commentForWinnerDrop;

      default:
        return "";
    }
  }

  if (point.category === pointCategoryUnforced) {
    switch (point.outcome) {
      case unforcedDoubleFault:
        return AppLocalizations.commentForUnforcedDoubleFault;

      case unforcedAlley:
        return AppLocalizations.commentForUnforcedAlley;

      case unforcedOut:
        return AppLocalizations.commentForUnforcedOut;

      case unforcedNet:
        return AppLocalizations.commentForUnforcedNet;

      default:
        return "";
    }
  }

  if (point.category === pointCategoryForced) {
    switch (point.outcome) {
      case forcedConsistency:
        return AppLocalizations.commentForForcedConsistency;

      case forcedDepth:
        return AppLocalizations.commentForForcedDepth;

      case forcedDirection:
        return AppLocalizations.commentForForcedDirection;

      case forcedHeight:
        return AppLocalizations.commentForForcedHeight;

      case forcedPosition:
        return AppLocalizations.commentForForcedPosition;

      case forcedPower:
        return AppLocalizations.commentForForcedPower;

      case forcedSpin:
        return AppLocalizations.commentForForcedSpin;

      case forcedTime:
        return AppLocalizations.commentForForcedTime;

      default:
        return "";
    }
  }

  return "";
}
