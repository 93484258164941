export default (theme) => {
  return {
    root: {
      display: "flex",
      alignItems: "stretch",
      justifyContent: "center",
      flexDirection: "column",
      flex: 1,
      padding: 16,
      height: "calc(100% - 32px)",
      width: "calc(100% - 32px)",
    },
    bodyContainer: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "stretch",
      alignItems: "stretch",
    },
    appBarContainer: {
      flexGrow: 1,
      width: "100%",
      maxWidth: 600,
      margin: "auto",
      height: 0,
      marginTop: 16,
      overflowY: "scroll",
      display: "flex",
      justifyContent: "center",
    },
  };
};
