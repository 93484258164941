// import PropTypes from 'prop-types';
// material-ui
import { filterForMatchColors } from "constants/tennis";
import React from "react";

export default function ShotIcon({
  point,
  height,
  primaryColor,
  filter = true,
}) {
  if (point.category && point.outcome) {
    return (
      <div style={{ textAlign: "center" }}>
        <img
          src={`https://storage.googleapis.com/wid-images/tennis/${point.category}_${point.outcome}.png`}
          alt={``}
          style={{
            height: height || 18,
            filter: filter && filterForMatchColors[primaryColor],
          }}
        />
      </div>
    );
  }
  return <div />;
}
