// import PropTypes from 'prop-types';
// material-ui
import { Tooltip, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import FaceIcon from "@material-ui/icons/Face";
import { AppLocalizations } from "constants/localization";
import { textColorForMatchColor } from "constants/tennis";
import {
  getInitialForTeam,
  getMatchGraph,
  getPlayerName,
  printDuration,
  scoreLabels,
} from "helpers";
import ShotIcon from "hooks/ShotIcon";
import TennisMatchScore from "hooks/TennisMatchScore";
import React, { useEffect } from "react";
// styles
import styles from "./styles";

const useStyles = makeStyles(styles);

export default function TennisGraph({ tennisMatch }) {
  // const [ state, setState ] = React.useState(initialValue);
  const classes = useStyles(styles);
  const graph = getMatchGraph(tennisMatch);
  const { rule } = tennisMatch;
  const primaryColor = rule.color.toUpperCase();
  const secondaryColor = textColorForMatchColor[primaryColor];
  const firstGame = tennisMatch.sets[0].games[0];

  const ref = React.useRef(null);

  useEffect(() => {
    scrollToRight();
  }, [tennisMatch]);

  function getBarHeight(duration) {
    const barMaxHeight = 32;
    return duration / 2 + 5 > barMaxHeight ? barMaxHeight : duration / 2 + 5;
  }

  const scrollToRight = () => {
    ref.current.scrollTo({
      left: ref.current.scrollWidth,
      top: 0,
      behavior: "smooth",
    });
  };

  function getPlayerWonArea(player) {
    return (
      <div className={classes.playerWon}>
        <Typography variant="body2" display="block">
          {getPlayerName(player)}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {AppLocalizations.won.toUpperCase()}
        </Typography>
      </div>
    );
  }

  function getClockArea(game, winnerTeamID) {
    const JSX = [
      <Typography
        variant="body2"
        color="textSecondary"
        style={{ color: "transparent" }}
      >
        LOST
      </Typography>,
      <Typography variant="body2">
        <i class="far fa-stopwatch"></i>
        {` ${printDuration(Math.floor(game.duration))}`}
      </Typography>,
    ];
    return (
      <div className={classes.playerWon}>
        {winnerTeamID === 1 ? JSX.reverse() : JSX}
      </div>
    );
  }

  function getScoreArea(game) {
    const { winnerTeamID } = game;
    const racket = (show) => (
      <img
        src="https://storage.googleapis.com/wid-images/tennis/racket.png"
        alt="racket"
        height={12}
        style={{ opacity: show ? 1 : 0 }}
      />
    );

    return (
      <div className={classes.scoreArea}>
        <div style={{ display: "flex", alignItems: "center" }}>
          {racket(game.team1Serving)}
          <div
            className={classes.scoreBox}
            style={{
              background:
                winnerTeamID === 1 ? tintColor(primaryColor) : undefined,
              color:
                winnerTeamID === 1 ? textTintColor(secondaryColor) : undefined,
              borderColor:
                winnerTeamID === 1 ? tintColor(primaryColor) : undefined,
            }}
          >
            {game.t1SetScore}
          </div>
          {racket(false)}
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          {racket(!game.team1Serving)}
          <div
            className={classes.scoreBox}
            style={{
              background:
                winnerTeamID === 2 ? tintColor(primaryColor) : undefined,
              color:
                winnerTeamID === 2 ? textTintColor(secondaryColor) : undefined,
              borderColor:
                winnerTeamID === 2 ? tintColor(primaryColor) : undefined,
            }}
          >
            {game.t2SetScore}
          </div>
          {racket(false)}
        </div>
      </div>
    );
  }

  function getGameWinnerCard(point, game, set) {
    return (
      <div
        key={point.point.date}
        style={{ display: "flex", alignItems: "center" }}
      >
        <div
          className={classes.gameWinner}
          style={{ borderColor: tintColor(primaryColor) }}
        >
          {point.winnerTeamID === 1
            ? getPlayerWonArea(tennisMatch.players[0])
            : getClockArea(game, 1)}
          {getScoreArea(game)}
          {point.winnerTeamID === 2
            ? getPlayerWonArea(tennisMatch.players[1])
            : getClockArea(game, 2)}
        </div>
      </div>
    );
  }

  function getSetWinnerCard(set) {
    return (
      <div
        key={set?.startDate}
        style={{ display: "flex", alignItems: "center" }}
      >
        <div
          className={classes.gameWinner}
          style={{
            borderColor: tintColor(primaryColor),
            background: tintColor(primaryColor),
            textAlign: "center",
          }}
        >
          <Typography style={{ color: textTintColor(secondaryColor) }}>
            <b>Set {set.index}</b>
          </Typography>
          <div>
            <FaceIcon style={{ color: textTintColor(secondaryColor) }} />
            <Typography style={{ color: textTintColor(secondaryColor) }}>
              <b>{getInitialForTeam(tennisMatch.players, set.winnerTeamID)}</b>
            </Typography>
          </div>
          <Typography
            variant="body2"
            style={{ color: textTintColor(secondaryColor) }}
          >
            <i class="far fa-stopwatch"></i>
            {` ${printDuration(Math.floor(set.duration))}`}
          </Typography>
        </div>
      </div>
    );
  }

  function tintColor(primaryColor) {
    if (primaryColor === "#FFFFFF") return "#000000";

    return primaryColor;
  }

  function textTintColor(secondaryColor) {
    if (secondaryColor === "#000000") return "#FFFFFF";

    return secondaryColor;
  }

  function getPointBar(g, p) {
    const labels = scoreLabels(p.t1PointScore, p.t2PointScore, g.tieBreak);
    const { winnerTeamID } = p;
    const JSX = (
      <div>
        <div className={classes.pointBarContainer}>
          <div className={classes.column}>
            <div
              className={classes.barContainer}
              style={{ justifyContent: "flex-end" }}
            >
              <div
                style={{
                  background:
                    winnerTeamID === 1 ? tintColor(primaryColor) : undefined,
                  height: getBarHeight(p.duration),
                }}
                className={classes.bar}
              />
            </div>
            <Typography
              style={{ fontWeight: winnerTeamID === 1 ? "bold" : "normal" }}
              variant="caption"
            >
              {labels[0].toUpperCase()}
            </Typography>
          </div>
          <div className={classes.column}>
            <Typography
              style={{ fontWeight: winnerTeamID === 2 ? "bold" : "normal" }}
              variant="caption"
            >
              {labels[1].toUpperCase()}
            </Typography>
            <div className={classes.barContainer}>
              <div
                className={classes.bar}
                style={{
                  background:
                    winnerTeamID === 2 ? tintColor(primaryColor) : undefined,
                  height: getBarHeight(p.duration),
                }}
              />
            </div>
          </div>
          <div style={{ position: "absolute", bottom: 0 }}>
            <ShotIcon
              point={p.point}
              primaryColor={tintColor(primaryColor)}
              filter={false}
            />
          </div>
        </div>
      </div>
    );

    if (p.point.category) {
      return (
        <Tooltip title={`${p.point.category}: ${p.point.outcome}`}>
          {JSX}
        </Tooltip>
      );
    }
    return JSX;
  }

  return (
    <div
      className={`scrollBar ${classes.container}`}
      ref={ref}
      onClick={scrollToRight}
    >
      {firstGame.points.length > 0 ? (
        <div className={classes.score}>
          <TennisMatchScore tennisMatch={tennisMatch} />
        </div>
      ) : (
        []
      )}
      {graph.sets.map((s) => {
        const S_JSX = [
          <>
            {s.games.map((g) => {
              return (
                <>
                  {g.points.map((p) => {
                    const JSX = [getPointBar(g, p)];
                    if (g.winnerTeamID != null && p.winningPoint) {
                      JSX.push(getGameWinnerCard(p, g, s));
                    }
                    return JSX;
                  })}
                </>
              );
            })}
          </>,
        ];

        if (
          tennisMatch.completed ||
          (s.winnerTeamID != null && s.winnerTeamID > 0)
        ) {
          S_JSX.push(getSetWinnerCard(s));
        }
        return S_JSX;
      })}
      {firstGame.points.length === 0 ? (
        <div className={classes.notStarted}>
          <div style={{ textAlign: "center" }}>
            <Typography color="textSecondary">Match has not started</Typography>
            <Typography variant="caption" color="textSecondary">
              <i class="fad fa-chart-bar"></i> Live graph will show the
              progression shortly
            </Typography>
          </div>
        </div>
      ) : (
        []
      )}
    </div>
  );
}
